import React from 'react'
import { useNavigate } from 'react-router-dom'
import useAuthorization from './useAuthorization'
// import logo from "../assets/Awbay HELPLabor Coping Scale-2.jpg"
import logo from "../assets/help-logo.png"
const AdminHome = ({patientId,hospitalName}) => {
  useAuthorization(['admin'])
  const navigate = useNavigate()

  const token=localStorage.getItem("token")
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('selectedRole');
    navigate('/'); // Redirect to login page after logout
  };
  console.log(hospitalName,"hosp")
  return (
    <div className='home-box main'>
       <div className='staff-logout-div absolute-btn'>
          <button className='logout-btn' onClick={handleLogout}>Logout</button>
        </div>
        <h2 className='fw-bold  '>{hospitalName}</h2>
        <div className='logo-div2 mt-2 '>
                <img src={logo}/>
            </div>
        <button className='next-btn' onClick={()=>navigate("/signup")}>Create new staff </button>
        <button className='next-btn '  onClick={()=>navigate("/stafflist")}>View staff list</button>
        <button className='next-btn '  onClick={()=>navigate("/allassessments")}>View Assessments</button>

       
    </div>
  )
}

export default AdminHome
