import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import SignUp from './Pages/SignUp';
import Login from './Pages/Login';
import Main from './Pages/Main';
import Home from './Pages/Home';
import PatientForm from './Pages/PatientForm';
import Assessment from './Pages/Assessment';
import Result from './Pages/Result';
import { useState } from 'react';
import AdminHome from './Pages/AdminHome';
import StaffList from './Pages/StaffList';
import ForgetPassword from './Pages/forgetPassword';
import ResetPassword from './Pages/ResetPassword';
import SuperLogin from './Pages/SuperLogin';
import CreateAdmin from './Pages/CreateAdmin';
import SuperAdminHome from './Pages/SuperAdminHome';
import AdminList from './Pages/AdminList';
import AllAssessments from './Pages/AllAssessments';
function App() {
  const [patientId,setPatientId]=useState("")
  const [hospitalName, setHospitalName] = useState('');

  return (
    <div className="App">

     <BrowserRouter>
     <Routes>
     {/* <Route exact path="/" element={<Main/>} /> */}
     <Route exact path="/home" element={<Home patientId={patientId}/>} />
     <Route exact path="/superuser" element={<SuperLogin/>} />
     <Route exact path="/createadmin" element={<CreateAdmin/>} />
     <Route exact path="/superadminhome" element={<SuperAdminHome/>} />

     <Route exact path="/adminhome" element={<AdminHome patientId={patientId} hospitalName={hospitalName}/>} />

     <Route exact path="/result" element={<Result />} />

     <Route exact path="/signup" element={<SignUp hospitalName={hospitalName}/>} />
     <Route exact path="/patientform" element={<PatientForm patientId={patientId} setPatientId={setPatientId}/>} />

     <Route exact path="/assessment" element={<Assessment/>} />
     <Route exact path="/allassessments" element={<AllAssessments/>} />

     <Route exact path="/" element={<Login setHospitalName={setHospitalName}/>} />
     <Route exact path="/stafflist" element={<StaffList hospitalName={hospitalName}/>} />
     <Route exact path="/adminlist" element={<AdminList />} />

     <Route exact path="/forgetpassword" element={<ForgetPassword />} />
     <Route exact path="/accounts/password_reset/confirm/" element={<ResetPassword />} />

     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
