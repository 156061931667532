import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/apiInstance';
import useAuthorization from './useAuthorization';
import { useNavigate } from 'react-router-dom';


const StaffList = ({ hospitalName }) => {
    useAuthorization(['admin'])

  const token = localStorage.getItem('token');
  const [staff, setStaff] = useState([]);
  const navigate= useNavigate()

  const getStaffList = async () => {
    try {
      const response = await axiosInstance.get('api/create_staff/', {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
       const sortedStaff = response.data.sort((a, b) => a.first_name.localeCompare(b.first_name));

      setStaff(sortedStaff);
    } catch (error) {
      console.error('Error fetching staff list:', error);
    }
  };

  const handleDeleteStaff = async (id) => {
    try {
      await axiosInstance.delete(`api/delete_staff/${id}/`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setStaff((prevList) => prevList.filter((staff) => staff.id !== id));
    } catch (error) {
      console.error('Error deleting staff member:', error);
    }
  };

  useEffect(() => {
    getStaffList();
  }, []);
  const handleBack = () => {
   
    navigate('/adminhome'); 
  };
  return (
    <div className="staff-div">
            <div className='flex w-100 m-2'>

                            <button className='go-back-btn' onClick={handleBack}><i className="fa fa-long-arrow-left"></i>Go Back</button>
</div>
        <h2 className='text-center mt-4'>
        {hospitalName}
      </h2>
      <h3 className='text-center'>List of Staff Members</h3>
    
      <div className='table-div'>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th></th>
            <th>Date Added</th>
            <th>Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {staff.map((staff, index) => (
            <tr key={staff.id}>
              <td>{index + 1}</td>
              <td>{new Date(staff.date_joined).toLocaleDateString()}</td>
              <td>{staff.first_name}</td>
              <td>{staff.email}</td>
              <td>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteStaff(staff.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default StaffList;
