import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/apiInstance';
import useAuthorization from './useAuthorization';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

const AllAssessments = () => {
  const token = localStorage.getItem('token');
  const [assessments, setAssessments] = useState([]);

  const navigate = useNavigate()

  const handlePDF = async (id, name) => {
    try {
      const response = await axiosInstance.get(`api/generate_pdf/${id}/`, { responseType: 'blob' });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `HELPLabor.${name}.pdf`);
    } catch (error) {
      console.error('Error generating pdf:', error);
    }
  }

  const handleRowClick = (id,pt_name) => {
    navigate(`/result`, {state: {id: id, status: 'Admin',name:pt_name}})
  }

  const getAssessmentList = async () => {
    try {
      const response = await axiosInstance.get('api/list_assessments/', {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const sortedAssessments = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      setAssessments(sortedAssessments);      
    } catch (error) {
      console.error('Error fetching assessment list:', error);
    }
  };

  useEffect(() => {
    getAssessmentList();
  }, []);
  const handleBack = () => {

    navigate('/adminhome');
  };
  return (
    <div className="staff-div">
      <div className='flex w-100 m-2'>
        <button className='go-back-btn' onClick={handleBack}><i className="fa fa-long-arrow-left"></i>Go Back</button>

      </div>

      <h2 className='text-center mt-4 '>HELPLabor Assessments</h2>
      <div className='table-div'>
      <table className='table table-striped'>        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Time</th>
            <th>Submitted by</th>
            <th>Patient Name</th>
            <th>Result</th>
            <th>Status</th>
            <th></th>

            <th></th>

          </tr>
        </thead>
        <tbody>
          {assessments.map((assessment, index) => (
            <tr key={assessment.id}>
              <td>{index + 1}</td>
              <td>{assessment.created_by ? new Date(assessment.created_at).toLocaleDateString() : ''}</td>
              <td>{assessment.created_by ? new Date(assessment.created_at).toLocaleTimeString(undefined, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true,
              }) : ''}</td>
              <td>{assessment.created_by.first_name}</td>
              <td>{assessment.patient_name}</td>
              <td>{assessment.result}</td>
              <td>{assessment.status}</td>
              <td><button className='common-btn' onClick={() => {
                handleRowClick(assessment.id,assessment.patient_name)
              }} >View</button></td>

              <td><button className='common-btn' onClick={() => handlePDF(assessment.id, assessment.patient_name)}>Download</button></td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default AllAssessments;
