import React from 'react'
import { useNavigate } from 'react-router-dom'
import useAuthorization from './useAuthorization'
import logo from "../assets/iconHelp.jpeg"
const SuperAdminHome = ({patientId,hospitalName}) => {
  // useAuthorization(['admin'])
  const navigate = useNavigate()

  const token=localStorage.getItem("token")
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('selectedRole');
    navigate('/'); // Redirect to login page after logout
  };
  console.log(hospitalName,"hosp")
  return (
    <div className='home-box main superadmin-home'>
       <div className='staff-logout-div absolute-btn'>
          <button className='logout-btn' onClick={() => handleLogout()}>Logout</button>
        </div>
        <div className='super-logo'>
          <img src={logo}/>
        </div>
        <h3>
          HELPLabor
          <br/>
          Organizations
        </h3>
        <button className='next-btn' onClick={()=>navigate("/createadmin")}>Create new account </button>
        <button className='next-btn '  onClick={()=>navigate("/adminlist")}>View list</button>
       
    </div>
  )
}

export default SuperAdminHome
