import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/apiInstance';
import CryptoJS from 'crypto-js';
import useAuthorization from './useAuthorization';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SignUp = ({hospitalName}) => {
  useAuthorization(['admin'])
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  

  
  const token=localStorage.getItem("token")
  const handleLogout = () => {
   
    navigate('/adminhome'); 
  };
 
console.log(token,"tokenn")
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!firstName  || !email || !password) {
      toast.error("Please fill all fields.", {
        position: toast.POSITION.TOP_RIGHT
      });
      return; 
    }
    try {
     
      const response = await axiosInstance.post('api/create_staff/', {
        first_name: firstName,
        email,
        password,
      }, {
        headers: {
          'Authorization': `Token ${token}`, 
          'Content-Type': 'application/json',
        }},);

        if (response.status === 200 || response.status === 201) {
          toast.success("Successfully created staff", {
            position: toast.POSITION.TOP_RIGHT
          });
        navigate("/adminhome")}
        
    } catch (error) 
      {
        const errorResponse = error.response.data;
        if (errorResponse.email) {
          toast.error(errorResponse.email[0], {
            position: toast.POSITION.TOP_RIGHT
          });
        }
    
        if (errorResponse.password && errorResponse.password[0] === "This password is too common.") {
          toast.error("Password is too common.", {
            position: toast.POSITION.TOP_RIGHT
          });
        } else if (errorResponse.password) {
          toast.error("Password invalid. Password should be alpha-numeric and contain atleast 8 characters", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
    
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  }
  const passwordInputType = showPassword ? 'text' : 'password';

  return (
    <div className="containerr main">
      <form className="signup-form" onSubmit={handleFormSubmit}>
        <div className='logout-div'>
          <button className='go-back-btn' onClick={handleLogout}><i className="fa fa-long-arrow-left"></i>Go Back</button>
        </div>
        <h1>Create New Staff Account</h1>
        <div className="form-group">
          <label htmlFor="firstName"><i className="fa fa-user"></i>Staff Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={handleInputChange}
            required
          />

        </div>
        {/* <div className="form-group">
          <label htmlFor="lastName"><i className="fa fa-user"></i>Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={lastName}
            onChange={handleInputChange}
          />

        </div> */}
        <div className="form-group">
          <label htmlFor="email"><i className="fa fa-envelope"></i> Staff Email</label>
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            placeholder="xyz@email.com"

            required
          />

        </div>
        <div className="form-group">
          <label htmlFor="password"><i className="fa fa-lock"></i>Password</label>
          <div className="password-input">
            <input
              type={passwordInputType}
              id="password"
              name="password"
              value={password}
              onChange={handleInputChange}
              required
              placeholder="Password must be alphanumeric and atleast 8 characters long"

            />
            <span className="toggle-password" onClick={handleTogglePassword}>
            <i className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
            </span>
          </div>



        </div>
        <div className="submit-btn">
          <button type="submit" onClick={handleFormSubmit}>Submit</button>
        </div>
      </form>
    </div>
  );
}

export default SignUp;
