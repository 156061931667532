import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuthorization from './useAuthorization'
import axiosInstance from '../api/apiInstance'
import logo from "../assets/help-logo.png"

const Home = () => {
  useAuthorization(['staff'])
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  console.log(state, "state")

  const token = localStorage.getItem("token")
  const [assessments, setAssessments] = useState([]);
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('selectedRole');
    navigate('/'); // Redirect to login page after logout
  };



  const getAssessmentList = async () => {
    try {
      const response = await axiosInstance.get('api/pending_assessment/', {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setAssessments(response.data);
    } catch (error) {
      console.error('Error fetching assessment list:', error);
    }
  };

  useEffect(() => {
    getAssessmentList();
  }, []);

  const handlePatientRow = (id) => {
    const state = {
      id: id.id,
    };
    console.log(state, "ststst")
    navigate('/assessment', { state })
  }

  const handlePatientRow2 = (id,name) => {
    const state = {
      id: id,
      name:name
    };
    console.log(state, "ststst")
    navigate('/assessment', { state })
  }
  return (
    <div className='home-div '>
      <div className='staff-logout-div absolute-btn mb-5'>
        <button className='logout-btn' onClick={handleLogout}>Logout</button>

      </div>
      <div className='logo-div2  mt-5'>
                <img src={logo} className='mt-5'/>
            </div>
      <button className='next-btn' onClick={() => navigate("/patientform")}>Start HELPLabor</button>
      {/* <button className='next-btn btn-disable' disabled={!state?.id} onClick={() => handlePatientRow(state)} >Complete HELPLabor</button> */}

      <div className="pending-table">
        <h5 className='mt-3 fw-bold '>Assessment List</h5>
        <table className='table-striped'>
          <thead>
            <tr>
              <th></th>
              <th>Patient Name</th>
              <th>Status</th>
              <th></th>

            </tr>
          </thead>
          <tbody>
            {assessments.map((assessment, index) => (
              <tr key={assessment.id}>
                <td>{index + 1}</td>
                <td>{assessment.patient_name}</td>
                <td>{assessment.status}</td>
                <td>        <button className='common-btn ' onClick={() => handlePatientRow2(assessment.id,assessment.patient_name)}>Complete Assessment</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default Home
