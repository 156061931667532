import React, { useEffect, useState } from 'react'
import axiosInstance from '../api/apiInstance'
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import useAuthorization from './useAuthorization';
import logo from "../assets/Awbay HELPLabor Coping Scale-2.jpg"
const Result = () => {
    const location = useLocation()

    // useAuthorization(['staff'])

    const [data, setData] = useState([]);
    const [status, setStatus] = useState([]);
    console.log(location, "kkkk")
    const navigate = useNavigate();
    const handleNew = () => {
        navigate("/home")
    }
    const handlePDF = async () => {
        try {
            const response = await axiosInstance.get(`api/generate_pdf/${location.state.id}/`, { responseType: 'blob' });
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(pdfBlob, `HELPLabor.${location.state.name}.pdf`);
        } catch (error) {
            console.error('Error generating pdf:', error);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`api/categories/total_points/${location.state.id}/`)
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try {
                const response = await axiosInstance.get(`api/patient_coping_status/${location.state.id}/`)
                setStatus(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }


        fetchData();

    }, [location.state.id]); // Add patientId as a dependency

    // const lastTwoItems = data.slice(-3);

    const getCopingBoxColor = (totalPoints) => {
        if (totalPoints < 3) {
            return '#fc3a3a';
        } else if (totalPoints >= 3 && totalPoints <= 7) {
            return '#62d6fc';
        } else if (totalPoints > 7) {
            return '#ed62fc';
        }
    }
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('selectedRole');
        navigate('/'); // Redirect to login page after logout
    };
    const handleBack = () => {

        navigate('/allassessments');
      };
    return (
        <div className='result-table'>
            {location.state.status ==="Admin"
            && 
            <div className='flex w-100 m-2'>
        <button className='go-back-btn' onClick={handleBack}><i className="fa fa-long-arrow-left"></i>Go Back</button>

      </div>
            }
            
            <div className='logo-div2'>
                <img src={logo} />
            </div>
            <h4><strong>Laboring Person Name:  </strong>{location.state.name}</h4>
            <table>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td className='align-left'>{item.category_name}</td>
                            <td className='align-right'>{item.total_points}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div>

                <div className='coping-box mb-3 text-center' style={{ backgroundColor: getCopingBoxColor(status.total_points) }}>
                    {status.coping_status}
                </div>
            </div>

            {location.state.status === 'Staff' &&
                <><button className='next-btn' onClick={handlePDF}>Download PDF</button>
                    <button className='next-btn' onClick={handleNew}>Start New Assessment</button>

                    <button className=' w-auto m-4 py-2 px-4 border-0 result-logout' onClick={handleLogout}>Logout</button></>}

        </div>
    )
}

export default Result
