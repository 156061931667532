// Updated SelectOne Component
import React from 'react';

const SelectOne = ({ title, heading, subheading, titles,patientId, options, addAnswer, selectedOption }) => {

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
      const category = title;
      const question = heading;
      const answer = {
        answer: selectedValue,
        points: options[selectedValue].points,
        patient: patientId
      };
  
      addAnswer(category, question, answer);
    
   
  };

  return (
    <div className="select-one">
      <h4 className='question-heading'>{title}</h4>
      <h3>{heading}</h3>
      {subheading && <p>{subheading}</p>}
      <div className="options">
        {titles.map((option) => (
          <div key={option} className="option">
            <input
              type="radio"
              id={option}
              name={heading}
              value={option}
              checked={selectedOption === option}
              onChange={handleOptionChange} c
              className='cursor-pointer '
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectOne;
