import React, { useState, useEffect } from 'react';
import SelectOne from './SelectOne';
import SelectMultiple from './SelectMultiple';
import axiosInstance from '../api/apiInstance';
import SelectPosition from './SelectPosition';
import { useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import useAuthorization from './useAuthorization';
import Modal from 'react-modal';
Modal.setAppElement('#root');

const Assessment = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state,"kkk")
  let patientId = state.id
  let patientName=state.name
  useAuthorization(['staff'])

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [answers, setAnswers] = useState([]); 
  const [selectedOption, setSelectedOption] = useState('');
  const [encryptionKey, setEncryptionKey] = useState(null);
  const [encryptionIV, setEncryptionIV] = useState(null);
  const [base64EncodedKey, setBase64EncodedKey] = useState('');
  const [base64EncodedIV, setBase64EncodedIV] = useState('');
  const [reachedLastQuestion, setReachedLastQuestion] = useState(false);
const navigate= useNavigate()

const [showModal, setShowModal] = useState(false);

const handleGoBackClick = () => {
  if (currentQuestion > 1) {
    setCurrentQuestion(currentQuestion - 1);
    setSelectedOption('');
  } else if (currentQuestion === 1) {
    setShowModal(true); 
};}


const handleCancel = () => {
  setShowModal(false);
};

const handleConfirm = () => {
  setShowModal(false);
  navigate("/home")
};
  const handleNextClick = () => {

    if (currentQuestion < 4) {
      setCurrentQuestion(currentQuestion + 1);
    } if (currentQuestion===4){
      setReachedLastQuestion(true);
    }
    setSelectedOption('');
  };

  const generateKeyAndIV = () => {
    try {
      const keyLength = 128; // Choose the desired key length (128, 192, or 256 bits)
      const key = CryptoJS.lib.WordArray.random(keyLength / 8);
      const iv = CryptoJS.lib.WordArray.random(16); // 16 bytes IV
      setEncryptionKey(key);
      setEncryptionIV(iv);
  
      // Convert key and IV to byte arrays
      const keyBytes = CryptoJS.enc.Hex.parse(key.toString());
      const ivBytes = CryptoJS.enc.Hex.parse(iv.toString());
  
      // Convert byte arrays to base64-encoded strings
      const keyBase64 = CryptoJS.enc.Base64.stringify(keyBytes);
      const ivBase64 = CryptoJS.enc.Base64.stringify(ivBytes);
  
      setBase64EncodedKey(keyBase64);
      setBase64EncodedIV(ivBase64);
    } catch (error) {
      console.error('Key and IV generation error:', error);
    }
  };
  useEffect(() => {
   
   
   
    generateKeyAndIV();
  }, []);

  const encryptData = (data, key, iv) => {
    if (!key || !iv) {
      console.error('Encryption key or IV not available.');
      return null;
    }
    const encryptedData = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedData.toString();
  };
 

 
  const handleSubmit = async () => {
   

    const encryptedData = answers.map((category) => ({
      category_name: encryptData(category.category_name, encryptionKey, encryptionIV),
      questions: category.questions.map((question) => ({
        question: encryptData(question.question, encryptionKey, encryptionIV),
        answers: question.answers.map((answer) => ({
          answer: encryptData(answer.answer, encryptionKey, encryptionIV),
          points: answer.points,
          patient: answer.patient,
        })),
      })),
    }));
  console.log(encryptedData,"kkk")
    try {
      const response = await axiosInstance.post('api/categories/', 
      encryptedData
      , {
        headers: {
          "Content-Type": "application/json",
          'Encryption-Key': base64EncodedKey,
          'Encryption-IV': base64EncodedIV,
        },
      });
      navigate("/result", {state: {id: patientId, name: patientName, status: 'Staff'}});
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  }
  
const addAnswer = (category, question, answer) => {
    const existingCategory = answers.find(item => item.category_name === category);
  
    if (existingCategory) {
      const existingQuestion = existingCategory.questions.find(q => q.question === question);
  
      if (existingQuestion) {
        existingQuestion.answers.push(answer);
      } else {
        existingCategory.questions.push({
          question: question,
          answers: [answer]
        });
      }
    } else {
      answers.push({
        category_name: category,
        questions: [
          {
            question: question,
            answers: [answer]
          }
        ]
      });
    }
  
    setSelectedOption(answer.answer || answer);
  };
  


  const addAnswer2 = (category, question, answer) => {
    const categoryIndex = answers.findIndex(item => item.category_name === category);
    if (categoryIndex !== -1) {
      const questionIndex = answers[categoryIndex].questions.findIndex(q => q.question === question);
      if (questionIndex !== -1) {
        answers[categoryIndex].questions[questionIndex].answers = [answer];
      } else {
        answers[categoryIndex].questions.push({
          question: question,
          answers: [answer]
        });
      }
    } else {
      answers.push({
        category_name: category,
        questions: [
          {
            question: question,
            answers: [answer]
          }
        ]
      });
    }
  
    if (answer.answer) {
      setSelectedOption(answer.answer);
    } else {
      setSelectedOption(answer);
    }
  };

  console.log(answers,"answerss")
  const Human = {
    "No support available":{points:0},
    "No assistance":{points:0},
    "Present":{points:1},
    "Assisting":{points:2},
    "Explaining":{points:2},
    "Advocating":{points:3},
    "Attentive":{points:3},
    "Suggesting":{points:3},
    "Affirming":{points:3},
    
  };

  const Emotion = {
   
    "Terrified":{points:0},
    "Panicked":{points:0},
    "Fatigued":{points:1},
    "Self-conscious":{points:1},
    "Discouraged":{points:1},
    "Hesitant":{points:1},
    "Unwilling":{points:1},
    "Committed":{points:3},
    "Controlled":{points:3},
    "Confident":{points:3},
    "Calm":{points:3},
    "Involved":{points:3},
    "Willing":{points:3},
    "Concerned":{points:2},
    "Nervous":{points:2},
    "Unsure":{points:2},


  };

  const Options3 = {
    "Tools": {
      options: ["Breathing Techniques","Visualizations","Affirmations",
      "BirthBall/Peanut Ball","Rebozo/Scarf","Stress Balls",
      "Fan Manual/Battery","Soak Feet Water","Tub",
      "Shower","Music","Focal points","Hot/Cold Compresses",
      "Unscented Oil","Aromatherapy","Rocking Chair","LED Candles",
      "Vocalization/Moaning","TENS Machine","Massager Manual/battery"
      ,"Medication","None"],
      points: [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0]
    },
    "Environment": {
      options: ["Dim Lights","Increase Privacy","Adjust Room Temperature","Decrease Noise","None"],
      points: [1,1,1,1,0]
    },
    "Change": {
      options: ["Change Clothes/Gown","Change Bed Linen","Offer Food","Offer Fluids","Assist to Urinate","None"],
      points: [1,1,1,1,1,0]
    },
    "Hands": {
      options: ["Massage","Counter Pressure","Hip Squeeze","Knee Press","None"],
      points: [1,1,1,1,0]
    }
  };
  const Options4 = {
    "Positions": {
      options: ["Walking", "Standing","Sitting","Squatting","Lunging","Leaning","Side-lying","Pelvic Rocking","Dancing","Kneeling","Straddle Legs Position","Hands and Knees","Swaying","Yoga Positions","None"],
      points: [1,1,1,1,1,1,1,1,1,1,1,1,1,1,0]
    }
  };
  const renderQuestion = () => {
    switch (currentQuestion) {
      case 1:
        return (
          <SelectOne
            title={'Human Assistance'}
            heading="Human Assistance for laboring person can be described as..."
            subheading="Select only one"
            titles={Object.keys(Human)}
            options={Human}
            addAnswer={addAnswer2}
            selectedOption={selectedOption}
            patientId={patientId}
          />
        );
      case 2:
        return (
          <SelectOne
            title={'Emotional State'}
            heading="Emotional State for laboring person can be described as..."
            titles={Object.keys(Emotion)}
            options={Emotion}
            addAnswer={addAnswer2}
            selectedOption={selectedOption}
            patientId={patientId}
            subheading="Select only one"

          />
        );
      case 3:
        return (
          <SelectMultiple
            title={'Labor Tools (T.E.C.H.)'}
            heading=""
            titles={["Tools", "Environment", "Change", "Hands"]}
           options={Options3}
            subheading="Select one or more"
            addAnswer={addAnswer}
            patientId={patientId}
          />
        );
        case 4:
        return (
          <SelectPosition
            title={'Position Changes'}
            heading="Select as many used"
            titles={["Positions"]}
            options={Options4}
            // subheading="Select one or more"
            addAnswer={addAnswer}
            patientId={patientId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="containerr">
      <div className="assessment-container flex-col">
        <div>{renderQuestion()}</div>
        <div className='flex-row quiz-btns'>

       
          <button type="button" className="next-quest-btn" onClick={handleGoBackClick}>
            Previous
          </button>
       
          <button type="button" className="next-quest-btn" onClick={currentQuestion===4?handleSubmit:handleNextClick}>
             {currentQuestion===4 ? "Submit":"Next"}
          </button>
      
         </div>
        
      </div>
      <Modal 
        isOpen={showModal}
        onRequestClose={handleCancel}
        contentLabel="Cancel Assessment Modal"
      >
        <div className="modal-content">
          <p>Do you want to cancel this assessment?</p>
          <div className='d-flex gap-2 justify-content-end '>

          
          <button onClick={handleCancel} className='modal-btn1'>No</button>
          <button onClick={handleConfirm} className='modal-btn2'>Yes</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Assessment;












