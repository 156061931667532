import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/apiInstance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from "../assets/Awbay HELPLabor Coping Scale-2.jpg"
const Login = ({setHospitalName}) => {
  const [selectedRole, setSelectedRole] = useState('admin');
  const [userEmail, setUserEmail] = useState('');
  const [userPass, setUserPass] = useState('');
  const [token, setToken] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  }



  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setUserPass(e.target.value);
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!userEmail || !userPass) {
      toast.error('Please fill in all fields.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
   

    try {
      const response = await axiosInstance.post('accounts/login/', {
        email: userEmail,
        password: userPass,
        check_per:selectedRole
      });
      setHospitalName(response.data.hospital)
console.log(response.data.hospital,"resp")
toast.success("Successfully logged in.", {
  position: toast.POSITION.TOP_RIGHT
});
if (response.status === 200 || response.status===201) {
  const token = response.data.token;
  console.log(token,"tokeen")
  localStorage.setItem("token", token);

  if (selectedRole === 'admin') {
    navigate('/adminhome');
    localStorage.setItem("token", token);
    localStorage.setItem("selectedRole", selectedRole);
  } else if (selectedRole === 'staff') {
    navigate('/home');
    localStorage.setItem("token", token);
    localStorage.setItem("selectedRole", selectedRole);
  }
}

sessionStorage.setItem("token",token)
    } catch (error) {
      console.error('Error loging in:', error);

      if (error.response) {
        toast.error("Email or password is incorrect.", {
          position: toast.POSITION.TOP_RIGHT
        });}
    }
  };


  const passwordInputType = showPassword ? 'text' : 'password';

  return (
    <div className="containerr main">
  

      
      <form className="signup-form">
      <div className='logo-div'>
  <img src={logo}/>
</div>
        <div className='radios-box'>
          <label>
            <input
              type="radio"
              value="admin"
              checked={selectedRole === 'admin'}
              onChange={handleRoleChange}
            />
            Admin
          </label>
          <label>
            <input
              type="radio"
              value="staff"
              checked={selectedRole === 'staff'}
              onChange={handleRoleChange}
            />
            Staff
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="email"><i className="fa fa-envelope"></i>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={userEmail}
            onChange={handleEmailChange}
            required
          />
           <div className="error-message">{emailError}</div>

        </div>
        <div className="form-group">
          <label htmlFor="password"><i className="fa fa-lock"></i>Password</label>
          <div className="password-input">
            <input
              type={passwordInputType}
              id="password"
              name="password"
              value={userPass}
              onChange={handlePasswordChange}
              required
            />
            <span className="toggle-password" onClick={handleTogglePassword}>
            <i className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
            </span>
          </div>


          <div className="error-message">{passwordError}</div>

        </div>
        <p className='forget-text text-end' onClick={()=>navigate("/forgetpassword")}>Forget Password?</p>
        <div className="submit-btn">
          <button type="submit" onClick={handleFormSubmit}>Login</button>
        </div>
      </form>
      </div>
    
  )
}

export default Login;
