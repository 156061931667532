import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../api/apiInstance';
function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
// const [resetToken,setResetToken]=useState("")
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        toast.error('Error: Passwords do not match.',{ 
            position: toast.POSITION.TOP_RIGHT,
            className: 'custom-toast',});
        return;
      }

      const resetToken = new URLSearchParams(window.location.search).get('token');


if (resetToken) {
  const currentUrl = window.location.href;

  const urlParams = new URLSearchParams(window.location.search);

  urlParams.delete('token');

  const newUrl = `${currentUrl.split('?')[0]}?${urlParams.toString()}`;

  window.history.replaceState(null, null, newUrl);
}

console.log(resetToken,"resss")

      const response = await axiosInstance.post(
        'accounts/password_reset/confirm/',
        {
          token: resetToken,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    
      if (response.status === 200) {
        toast.success(
          'Password reset successful. You can now log in with your new password.',{
            position: toast.POSITION.TOP_RIGHT,
            className: 'custom-toast',
          }
        )
        navigate("/")
      } else {
        toast.error('Error: Unable to reset your password.',{
            position: toast.POSITION.TOP_RIGHT,
            className: 'custom-toast',

        });
      }
    } catch (error) {
        const errorResponse = error.response.data;
console.log(error.response,"errr")
        if (errorResponse.password && errorResponse.password[0] === "This password is too common.") {
          toast.error("Password is too common.", {
            position: toast.POSITION.TOP_RIGHT,
            className: 'custom-toast',
          });
        } else if (errorResponse.password) {
          toast.error("Password invalid. Password should be alpha-numeric and contain atleast 8 characters", {
            position: toast.POSITION.TOP_RIGHT,
            className: 'custom-toast',
          });
         
        }
        else if(error.response.statusText==="Not Found"){
          toast.error('Error: Unable to reset your password.',{
            position: toast.POSITION.TOP_RIGHT,
            className: 'custom-toast',

        });
        }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  }
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }
    const passwordInputType = showPassword ? 'text' : 'password';
    const passwordConfirmInputType = showConfirmPassword ? 'text' : 'password';

  return (
    <div className='containerr main'>
      <form onSubmit={handleResetPassword} className='signup-form'>
        <h2 className='text-center'>Reset Password</h2>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <div className="password-input">
          <input
              type={passwordInputType}
              placeholder="Enter your new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
           <span className="toggle-password" onClick={handleTogglePassword}>
            <i className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
            </span>
            </div>
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <div className="password-input">
          <input
              type={passwordConfirmInputType}
              placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
           <span className="toggle-password" onClick={handleToggleConfirmPassword}>
            <i className={`fas ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
            </span>
            </div>
        </div>

        <div className="submit-btn">
          <button type="submit">Reset Password</button>
        </div>  
        <p className='text-center mt-2 mb-0 cursor-pointer forget-text  ' onClick={()=>{navigate("/")}}>Back to login</p> 

      </form>
    </div>
  );
}

export default ResetPassword;
