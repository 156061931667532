import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/apiInstance';
import useAuthorization from './useAuthorization';
import { useNavigate } from 'react-router-dom';


const AdminList = ({ hospitalName }) => {
    // useAuthorization(['admin'])
const navigate= useNavigate()
  const token = localStorage.getItem('token');
  const [staff, setStaff] = useState([]);

  const getStaffList = async () => {
    try {
      const response = await axiosInstance.get('api/create_admin/', {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data,"stafff")
      setStaff(response.data);
    } catch (error) {
      console.error('Error fetching staff list:', error);
    }
  };

  const handleDeleteStaff = async (id) => {
    try {
      await axiosInstance.delete(`api/delete_admin/${id}/`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setStaff((prevList) => prevList.filter((staff) => staff.id !== id));
    } catch (error) {
      console.error('Error deleting staff member:', error);
    }
  };

  useEffect(() => {
    getStaffList();
  }, []);

  const handleBack = () => {
   
    navigate('/superadminhome'); 
  };
  return (
    <div className="staff-div">
            <div className='flex w-100 m-2  '>

                <button className='go-back-btn' onClick={handleBack}><i className="fa fa-long-arrow-left"></i>Go Back</button>
</div>
      <h2 className=' text-center '>Enrollment List</h2>
      <h6>
        <strong></strong>
      </h6>
      <div className='table-div'>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th></th>
            <th>Date Joined</th>
            <th>Contact Name</th>
            <th>Organization Name</th>
            <th>Email</th>
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
          {staff.map((staff, index) => (
            <tr key={staff.id}>
              <td>{index + 1}</td>
              <td>{new Date(staff.date_joined).toLocaleDateString()}</td>
              <td>{staff.first_name}</td>
              <td>{staff.hospital.hospital_name}</td>
              <td>{staff.email}</td>
              <td>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteStaff(staff.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
   </div>
    </div>
  );
};

export default AdminList;
