import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/apiInstance';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import useAuthorization from './useAuthorization';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const PatientForm = ({ setPatientId }) => {
  useAuthorization(['staff'])

  const navigate = useNavigate();
  const [staffName, setStaffName] = useState('');
  const [labouringPersonName, setLabouringPersonName] = useState('');
  const [medicalNumber, setMedicalNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [encryptionKey, setEncryptionKey] = useState(null);
  const [encryptionIV, setEncryptionIV] = useState(null);
  const [base64EncodedKey, setBase64EncodedKey] = useState('');
  const [base64EncodedIV, setBase64EncodedIV] = useState('');
  const token = localStorage.getItem('token');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!medicalNumber) {
      toast.error("Medical number is required.", {
        position: toast.POSITION.TOP_RIGHT
      });
      return; 
    }
  
    const formattedDateOfBirth = moment(dateOfBirth).format('YYYY-MM-DD');

    // if (!labouringPersonName || !medicalNumber || !dateOfBirth) {
    //   toast.error("Please fill in all fields.", {
    //     position: toast.POSITION.TOP_RIGHT
    //   })
    // }
    if (dateOfBirth && !moment(dateOfBirth).isValid()) {
      toast.error("Please select a valid date of birth.", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
   
    if (!encryptionKey || !encryptionIV) {
      console.error('Encryption key or IV not available yet.');
      return;
    }

    // Encrypt the sensitive data
    const encryptedLabouringPersonName = encryptData(labouringPersonName, encryptionKey, encryptionIV);
    const encryptedMedicalNumber = encryptData(medicalNumber, encryptionKey, encryptionIV);
    const encryptedDOB = encryptData(formattedDateOfBirth, encryptionKey, encryptionIV);

    try {
      const response = await axiosInstance.post(
        'api/create_patient/',
        {'ciphertext':{
          patient_name: encryptedLabouringPersonName,
          medical_number: encryptedMedicalNumber,
          dob: encryptedDOB,}
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
            'Encryption-Key': base64EncodedKey,
            'Encryption-IV': base64EncodedIV,
          },
        }
      );
     
      console.log(response.data.id, 'resp');
      // setPatientId(response.data.id);
      toast.success("Patient Created", {
        position: toast.POSITION.TOP_RIGHT
      })
      let state={
        id:response.data.id
      }
      
      navigate('/home', {state});


    } catch (error) {
      const errorResponse = error.response.data;

      if (errorResponse.medical_number) {
        toast.error(errorResponse.medical_number[0], {
          position: toast.POSITION.TOP_RIGHT
        });
      } 
      console.error('Error creating patient:', error);
    }
  };

  const encryptData = (data, key, iv) => {
    if (!key || !iv) {
      console.error('Encryption key or IV not available.');
      return null;
    }
    const encryptedData = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC, // Use CBC mode
      padding: CryptoJS.pad.Pkcs7, // Use PKCS7 padding
    });
    return encryptedData.toString();
  };

  const generateRandomIV = () => {
    const words = CryptoJS.lib.WordArray.random(16);
    return CryptoJS.enc.Hex.stringify(words);
    // return words.toString()
    return words
  };

  const generateKeyAndIV = () => {
    try {
      const keyLength = 128; // Choose the desired key length (128, 192, or 256 bits)
      const key = CryptoJS.lib.WordArray.random(keyLength / 8);
      const iv = CryptoJS.lib.WordArray.random(16); // 16 bytes IV
      setEncryptionKey(key);
      setEncryptionIV(iv);
  
      // Convert key and IV to byte arrays
      const keyBytes = CryptoJS.enc.Hex.parse(key.toString());
      const ivBytes = CryptoJS.enc.Hex.parse(iv.toString());
  
      // Convert byte arrays to base64-encoded strings
      const keyBase64 = CryptoJS.enc.Base64.stringify(keyBytes);
      const ivBase64 = CryptoJS.enc.Base64.stringify(ivBytes);
  
      setBase64EncodedKey(keyBase64);
      setBase64EncodedIV(ivBase64);
    } catch (error) {
      console.error('Key and IV generation error:', error);
    }
  };
  useEffect(() => {
   
   
   
    generateKeyAndIV();
  }, []);
  const handleLogout = () => {
   
    navigate('/home'); 
  };
  return (
    <div className='containerr  patient-box '>
     
      <form className='patient-form '>
      <div className='logout-div'>
          <button className='go-back-btn' onClick={handleLogout}><i className="fa fa-long-arrow-left"></i>Go Back</button>
        </div>
        <h1>Laboring Person Information</h1>

        <div className='form-box'>
          <div className="form-group">
            <label htmlFor="labouringPersonName">First and Last Name</label>
            <input
              type="text"
              id="labouringPersonName"
              name="labouringPersonName"
              value={labouringPersonName}
              onChange={(e) => setLabouringPersonName(e.target.value)}
            />

          </div>
         
        </div>
        <div className='form-box'>
         <div className="form-group">
            <label htmlFor="medicalNumber">Medical Number</label>
            <input
              type="text"
              id="medicalNumber"
              name="medicalNumber"
              value={medicalNumber}
              onChange={(e) => setMedicalNumber(e.target.value)}
            />
                                {/* <div className="error-message">{errorMsg3}</div> */}

          </div>
         </div>
        <div className='form-box'>
          <div className="form-group">
            <label>Date of Birth</label>
            <DatePicker
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={dateOfBirth}
              onChange={(date) => setDateOfBirth(date)}
            />
                      {/* <div className="error-message">{errorMsg4}</div> */}

          </div>

        </div>
        <div className="submit-btn">
          <button type="submit" onClick={handleFormSubmit}>Submit</button>
        </div>
      </form>
    </div>
  )
}

export default PatientForm
