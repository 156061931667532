// useAuthorization.js

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthorization = (allowedRoles) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const selectedRole = localStorage.getItem('selectedRole');

    if (!token || !selectedRole) {
      navigate('/'); 
      return;
    }

    if (!allowedRoles.includes(selectedRole)) {
      navigate('/home'); 
    }
  }, [allowedRoles, navigate]);

  return null;
};

export default useAuthorization;
