import axios from "axios";
const axiosInstance = axios.create({
  crossDomain: true,
  withCredentials: true,
  baseURL: "https://backend.helplabor.app/",
  // baseURL:"https://2c76-119-155-3-247.ngrok-free.app/",

  headers: {
    "Content-Type": "application/json",
  },

});

export default axiosInstance;
