import React, { useState } from 'react';

const SelectPosition = (props) => {
  const { title, heading, titles, options, subheading, patientId, addAnswer } = props;
  const [selectedTitle, setSelectedTitle] = useState(props.titles[0]);



  // const patientId= localStorage.getItem("patient_id")
  const handleMulti = (option, points) => {
    let obj = {
      answer: option,
      points: points,
      patient: patientId
    }
    addAnswer('Positions Changes', props.titles[0], obj)
  }

  const renderDropdown = (title) => {
    return (
      <div className="dropdown">
        {options[title]?.options?.length > 0 && options[title]?.options?.map((option, index) => (
          <label key={index}>
            <input type="checkbox" value={option} onChange={() => handleMulti(option, options[title].points[index])} className='cursor-pointer' /> {option}
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className='questions'>
      <h4 className='question-heading'>{props.title}</h4>
      <h6>{props.heading}</h6>
      {props?.titles?.length > 0 && props?.titles?.map((item, index) => {
        return (
          <div>
            <div className='cursor-pointer'>
              <p>{item}</p>
            </div>
            {renderDropdown(item)}
          </div>
        )

      })}
    </div>
  );
};

export default SelectPosition;
