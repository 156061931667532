import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../api/apiInstance';
const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
const navigate =useNavigate()
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('accounts/password_reset/', {
        email: email
      });

      if (response.status === 200) {

        toast.success('Password reset email sent. Check your inbox.',{ position: toast.POSITION.TOP_RIGHT,
            className: 'custom-toast',});
      } else {
        toast.error('Unable to send the password reset email.',{ position: toast.POSITION.TOP_RIGHT,
            className: 'custom-toast',});
      }
    } catch (error) {
        const errorResponse = error.response.data;

        if (errorResponse.email) {
            toast.error(errorResponse.email[0], {
              position: toast.POSITION.TOP_RIGHT,
              className: 'custom-toast',
            });
          }
    }
  };

  return (
    <div className='containerr main flex-col'>
      <form onSubmit={handleResetPassword} className='flex flex-col signup-form'>
      <h2 className=' mb-5 text-center'>Forgot Password</h2>

         <div className="form-group">
            <label htmlFor="email">Email</label>
           <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

          </div>
          <div className="submit-btn">
          <button type="submit" >Submit</button>
        </div>    
        <p className='text-center mt-2 mb-0 cursor-pointer forget-text  ' onClick={()=>{navigate("/")}}>Back to login</p> 
   
  </form>
    </div>
  );
};

export default ForgetPassword;
