import React, { useState } from 'react';

const SelectMultiple = (props) => {
  const { title, heading, titles, options, subheading, addAnswer,patientId } = props;
  const [selectedTitle, setSelectedTitle] = useState(null);
  // const patientId= localStorage.getItem("patient_id")

  const handleTitleClick = (title) => {
    setSelectedTitle(selectedTitle === title ? null : title);
  };

  const handleMulti = (option, points) => {
    let obj = {
      answer: option,
      points: points,
      patient: patientId
    }
    addAnswer('Labor Tools (T.E.C.H.)', selectedTitle, obj)
  }

  const renderDropdown = (title) => {
    if (selectedTitle === title) {
      return (
        <div >
        <p style={{fontSize:"14px", fontWeight:"500"}}><i>Select as many used</i></p>
        <div className='dropdown'>
        {options[title].options.map((option, index) => (
            
            <label key={index}>
              <input type="checkbox" value={option} onChange={() => handleMulti(option, options[title].points[index])}  className='cursor-pointer'/> {option}
            </label>
          ))}
        </div>
         
        </div>
      );
    }
    return null;
  };

  return (
    <div className='questions'>
      <h4 className='question-heading'>{props.title}</h4>
      <h6>{props.heading}</h6>

      <div onClick={() => handleTitleClick(props.titles[0])} className='cursor-pointer'>
        <p>{props.titles[0]}</p>
      </div>
      {renderDropdown(props.titles[0])}

      <div onClick={() => handleTitleClick(props.titles[1])} className='cursor-pointer'>
        <p>{props.titles[1]}</p>
      </div>
      {renderDropdown(props.titles[1])}

      <div onClick={() => handleTitleClick(props.titles[2])} className='cursor-pointer'>
        <p>{props.titles[2]}</p>
      </div>
      {renderDropdown(props.titles[2])}

      <div onClick={() => handleTitleClick(props.titles[3])} className='cursor-pointer'>
        <p>{props.titles[3]}</p>
      </div>
      {renderDropdown(props.titles[3])}
    </div>
  );
};

export default SelectMultiple;
